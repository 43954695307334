<template>
  <div class="word-book-learn">
    <div class="back">
      <span @click="prev"
        ><i class="iconfont icon-class-right"></i>{{ i18n.Word.return }}</span
      >
      <div class="model-list">
        <div
          v-for="item of modelList"
          :key="item.id"
          class="model-item"
          :class="{ active: modelType === item.id }"
          @click="changeModel(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-container recite-content" v-if="isRecite">
        <div class="content-header">
          <div>
            <i class="f-icon icon-trumpet play-btn" @click.stop="clickVolume" />
            <audio :src="word.url" ref="audio" />
            <span @click="handleShowExplain" class="show-explain">
              <span v-if="showExplain">{{ i18n.Word.spelling }}</span>
              <span v-else>{{ i18n.Word.interpretation }}</span>
            </span>
          </div>
        </div>
        <div class="content-center" v-loading="loading">
          <span v-if="showExplain">
            <template v-if="word.explain">
              <div
                v-for="(explains, explainsIndex) in word.explain"
                :key="explainsIndex"
              >
                {{ explains }}
              </div>
            </template>
            <div v-else>{{ i18n.Word.NoInterpretation }}</div>
          </span>
          <span v-else>{{ word.name }}</span>
        </div>
        <div class="content-bottom">
          <div class="btn" @click="goPrev">
            <i class="f-icon icon-Left" />
            <span>{{ i18n.Word.previous }}</span>
          </div>
          <div class="total">{{ wordIndex + 1 }}/{{ total }}</div>
          <div class="btn" @click="goNext">
            <i class="f-icon icon-right" />
            <span>{{ i18n.Word.next }}</span>
          </div>
        </div>
      </div>
      <div class="content-container dictate-content" v-else>
        <div>
          <div
            class="content-header"
            :class="[{ green: isError === false }, { red: isError === true }]"
          >
            <el-input
              ref="input"
              class="word-input"
              :class="{ error: isError === 'wait' }"
              placeholder="Type what you hear"
              v-model="inputWord"
              @focus="showBtn = true"
              :disabled="isError !== ''"
            >
            </el-input>
            <div class="audio-btn">
              <i
                class="f-icon icon-trumpet play-btn"
                @click.stop="clickVolume"
              />
              <audio :src="word.url" ref="audio" />
            </div>
            <span class="error-text">
              <span v-if="isError === false">{{ i18n.Word.correct }}</span>
              <span v-if="isError === true">{{ i18n.Word.error }}</span>
            </span>
            <div class="right-btn" v-if="showBtn">
              <template v-if="isError !== ''">
                <div class="check-btn" v-if="isError === false" @click="goNext">
                  下一个
                </div>
                <div class="check-btn" v-else @click="tryAgain">
                  {{ i18n.Word.doIt }}
                </div>
              </template>
              <div
                class="check-btn"
                v-else
                @click="checkWord"
                :class="{ disabled: !inputWord }"
              >
                {{ i18n.Word.complete }}
              </div>
            </div>
          </div>
          <div class="content-center">
            <div v-if="isError === 'wait'" class="right-word">
              {{ word.name }}
            </div>
            <template v-else-if="showExplain">
              <i
                class="iconfont icon-eye icon-xianshi2"
                @click="handleShowExplain"
              ></i>
              <span class="parse-label"
                >{{ i18n.Word.textInterpretation }}：</span
              >
              <div v-if="word.explain">
                <div
                  v-for="(explains, explainsIndex) in word.explain"
                  :key="explainsIndex"
                >
                  {{ explains }}
                </div>
              </div>
              <div v-else>{{ i18n.Word.NoInterpretation }}</div>
            </template>
            <template v-else>
              <i
                class="iconfont icon-eye icon-yincang2"
                @click="handleShowExplain"
              ></i>
              <div>{{ i18n.Word.InterpretationHidden }}</div>
            </template>
          </div>
        </div>
        <div class="content-bottom">
          <div class="btn" @click="goPrev">
            <i class="f-icon icon-Left" />
            <span>{{ i18n.Word.previous }}</span>
          </div>
          <div class="total">{{ wordIndex + 1 }}/{{ total }}</div>
          <div class="btn" @click="goNext">
            <i class="f-icon icon-right" />
            <span>{{ i18n.Word.next }}</span>
          </div>
        </div>
      </div>
      <div class="operation-content">
        <el-tooltip
          class="item"
          effect="dark"
          placement="top"
          popper-class="word-book-learn-popper"
        >
          <div slot="content">
            <div class="operation-list">
              <div class="operation-item">
                <span>{{ i18n.Word.interpretation }}</span>
                <span class="item-key big left">
                  <i class="f-icon icon-shift" />Shift
                </span>
              </div>
              <template v-if="isRecite">
                <div class="operation-item">
                  <span>{{ i18n.Word.MarkMastery }}</span>
                  <span class="item-key big"
                    >Enter<i class="f-icon icon-huiche1"
                  /></span>
                </div>
              </template>
              <template v-else>
                <div class="operation-item">
                  <span>{{ i18n.Word.Submit }}</span>
                  <span class="item-key big"
                    >Enter<i class="f-icon icon-huiche1"
                  /></span>
                </div>
              </template>
              <div class="operation-item">
                <span>{{ i18n.Word.previous }}</span>
                <span class="item-key"><i class="f-icon icon-xiangxia"/></span>
              </div>
              <div class="operation-item">
                <span>{{ i18n.Word.next }}</span>
                <span class="item-key"
                  ><i class="f-icon icon-zhongfuwangge1"
                /></span>
              </div>
              <div class="operation-item">
                <span>{{ i18n.Word.PlayPronunciation }}</span>
                <span class="key-list">
                  <span class="item-key small">Ctrl</span>/<span
                    class="item-key"
                    ><i class="f-icon icon-MacCommandjian"
                  /></span>
                </span>
              </div>
            </div>
          </div>
          <div class="operation-btn">
            <span>{{ i18n.Word.Operating }}</span>
            <i class="f-icon icon-Information" />
          </div>
        </el-tooltip>

        <div class="tools">
          <TranslateWord styleType="color" :word="word.name">
            <i class="iconfont icon-tianjia"></i>
            <span>{{ i18n.Word.Add }}</span>
          </TranslateWord>

          <Checkbox
            v-model="word.know_status"
            :true-label="1"
            :false-label="0"
            class="word-checkbox"
            :tipMsg="i18n.Word.Correct"
            @change="handleKnow"
          >
            {{ i18n.Word.Mastered }}
          </Checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  apiWordList,
  apiBaseWordList,
  apiWordUserMark,
  apiWordBaseMark,
  apiBaseWordListOne,
  apiWordListOne,
  apiBaseWordRandList
} from '@/api/api.js'
import Checkbox from './components/check'
import TranslateWord from '@/components/TranslateWord/index'
import i18n from '@/i18n/i18n'

const MODEL_TYPE = [
  {
    name: i18n.locale === 'ch' ? '背诵模式' : 'Recitation mode',
    id: 'recite'
  },
  {
    name: i18n.locale === 'ch' ? '听写模式' : 'Dictation mode',
    id: 'dictate'
  }
]

const PAGE_SIZE = 20

export default {
  components: {
    Checkbox,
    TranslateWord
  },
  data() {
    return {
      cate: this.$route.params.id,
      type: this.$route.query.type,
      status: this.$route.query.status,
      wordId: this.$route.query.wordId,
      randKey: this.$route.query.randKey,
      page: 1,
      total: 0,
      list: [],
      wordIndex: 0,
      word: {},
      modelList: MODEL_TYPE,
      modelType: MODEL_TYPE[0].id,
      showExplain: false,
      loading: false,
      inputWord: '',
      isError: '',
      showBtn: false,
      oneWord: null
    }
  },
  computed: {
    isRecite() {
      return this.modelType === 'recite'
    },
    isUser() {
      return this.type === 'user'
    },
    listApi() {
      return this.isUser
        ? apiWordList
        : this.randKey
        ? apiBaseWordRandList
        : apiBaseWordList
    },
    markApi() {
      return this.isUser ? apiWordUserMark : apiWordBaseMark
    },
    listOneApi() {
      return this.isUser ? apiWordListOne : apiBaseWordListOne
    },
    i18n() {
      return this.$t('messages')
    }
  },
  async created() {
    await this.fetchOne()
    this.initList()
    document.addEventListener('keyup', this.handleEnter)
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleEnter)
  },
  methods: {
    handleEnter(event) {
      const keyCode = event.keyCode || event.which || event.charCode
      switch (keyCode) {
        case 16: // Shift
          {
            this.handleShowExplain()
          }
          break
        case 13:
          if (this.isRecite) {
            if (!this.word.know_status) {
              this.word.know_status = 1
              this.list[this.wordIndex].know_status = 1
              this.handleKnow()
            }
          } else if (this.showBtn && this.inputWord) {
            if (this.isError === '') {
              this.checkWord()
            } else if (this.isError === false) {
              this.goNext()
            } else {
              this.tryAgain()
            }
          }
          break
        case 38:
          {
            if (!this.showBtn || (this.showBtn && this.isError === 'wait')) {
              this.goPrev()
            }
          }

          break
        case 40: {
          if (!this.showBtn || (this.showBtn && this.isError === 'wait')) {
            this.goNext()
          }
        }
        case 91:
        case 93:
        case 224:
        case 17:
          this.clickVolume()
          break
      }
      event.preventDefault()
    },
    prev() {
      this.$router.go(-1)
    },
    changeModel(type) {
      this.modelType = type
      this.autoOperation()
    },
    async fetchOne() {
      if (!this.wordId) {
        return false
      }
      const { data } = await this.listOneApi({
        id: this.wordId
      })
      this.oneWord = data
    },
    async initList() {
      this.list = this.oneWord ? [this.oneWord] : []
      await this.fetchPageList(1)

      this.word = this.list[0]
      this.autoOperation()
    },
    async fetchPageList(page) {
      if (this.loading) {
        return false
      }

      // 少于5个，就加载下一页
      if (this.list.length - this.wordIndex > 5) {
        return false
      }

      // 如果超过总数量，就不再获取数据
      if (this.total !== 0 && this.list.length >= this.total) {
        return false
      }

      this.loading = true
      try {
        const { code, data } = await this.listApi({
          page: page,
          page_size: PAGE_SIZE,
          cate: this.cate,
          status: this.status,
          rand_key: this.randKey
        })
        if (code !== 200) {
          return
        }

        this.list = this.list.concat(
          this.oneWord
            ? data.list.filter((m) => m.id !== this.oneWord.id)
            : data.list
        )

        this.total = data.pager.total_cnt
        this.page = page
      } catch {}
      this.loading = false
    },
    clickVolume() {
      this.$refs.audio && this.$refs.audio.play()
    },
    autoOperation() {
      this.$nextTick(() => {
        this.clickVolume()
        this.$refs.input && this.$refs.input.focus()
      })
    },
    handleKnow() {
      const { id, learn_status, know_status } = this.word
      this.markApi({
        id,
        learn_status: know_status || learn_status,
        know_status
      }).then((res) => {
        this.$layer.msg(res.msg)
        if (res.code !== 200) {
          return
        }
      })
    },
    handleLearn() {
      const { id, know_status, learn_status } = this.word
      if (learn_status) {
        return
      }
      this.markApi({
        id,
        learn_status: 1,
        know_status
      }).then((res) => {
        this.list[this.wordIndex].learn_status = 1
      })
    },
    handleShowExplain() {
      this.showExplain = !this.showExplain
    },
    goPrev() {
      if (this.wordIndex === 0 || this.total === 1) {
        return
      }
      this.showBtn = false
      this.isError = ''
      this.inputWord = ''
      this.showExplain = false
      this.wordIndex = this.wordIndex - 1
      this.word = this.list[this.wordIndex]
      this.handleLearn()
      this.autoOperation()
    },
    goNext() {
      if (this.wordIndex >= this.total - 1 || this.total === 1) {
        return
      }
      this.showBtn = false
      this.isError = ''
      this.inputWord = ''
      this.showExplain = false
      this.wordIndex = this.wordIndex + 1
      this.word = this.list[this.wordIndex]
      this.handleLearn()
      this.autoOperation()
      this.fetchPageList(this.page + 1)
    },
    checkWord() {
      this.isError = this.inputWord.trim() !== this.word.name.trim()

      if (this.isError) {
        setTimeout(() => {
          this.isError = 'wait'
        }, 1000)
      } else {
        this.word.know_status = 1
        this.list[this.wordIndex].know_status = 1
        this.handleKnow()
      }
    },
    tryAgain() {
      this.isError = ''
      this.inputWord = ''
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    }
  }
}
</script>

<style lang="scss">
.word-book-learn-popper {
  &.el-tooltip__popper.is-dark {
    background: rgba(58, 58, 58, 0.8);
  }

  &.el-tooltip__popper[x-placement^='top'] .popper__arrow {
    border-top-color: rgba(58, 58, 58, 0.8);
  }

  &.el-tooltip__popper[x-placement^='top'] .popper__arrow::after {
    border-top-color: transparent;
  }

  .operation-list {
    padding: 0 20px;
    width: 178px;
  }

  .operation-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 4px;

    .key-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 75px;
    }

    .item-key {
      display: inline-block;
      min-width: 22px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 3px;
      color: #bfbfbf;

      i {
        font-size: 12px;
      }

      &.big {
        position: relative;
        width: 67px;
        text-align: left;
        padding-left: 10px;
        box-sizing: border-box;

        &.left {
          > i {
            position: relative;
            right: 0;
            margin-right: 6px;
          }
        }

        i {
          position: absolute;
          right: 7px;
          top: 0;

          &.space-icon {
            right: -3px;
          }
        }
      }

      &.small {
        width: 39px;
      }
    }
  }
}

.word-book-learn {
  color: #1f1f40;

  .el-checkbox__input {
    .el-checkbox__inner {
      &:hover {
        border: 1px solid #3b86ff;
      }
    }

    &.is-focus {
      .el-checkbox__inner {
        border-color: #d7e7ff;

        &:hover {
          border: 1px solid #3b86ff;
        }
      }
    }

    &.is-checked {
      .el-checkbox__inner {
        background-color: #3b86ff;
        border-color: #3b86ff;

        &::after {
          border: 2px solid #fff;
          border-left: 0;
          border-top: 0;
        }
      }
    }

    &.is-checked + .el-checkbox__label {
      color: #3b86ff;
    }
  }

  .back {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin: 6px 0 10px;

    i {
      display: inline-block;
      font-size: 12px;
      transform: rotateZ(180deg);
      vertical-align: text-top;
    }

    span {
      cursor: pointer;
    }

    .model-list {
      padding: 5px 6px;
      background: #ededed;
      display: flex;
      border-radius: 5px;

      .model-item {
        // width: 76px;
        height: 26px;
        text-align: center;
        line-height: 26px;
        cursor: pointer;
        color: #9c9c9c;
        padding: 3px 5px;

        &.active {
          color: #3b86ff;
          background: #ffffff;
          border-radius: 3px;
        }
      }
    }
  }

  .content {
    width: 690px;
    margin: 40px auto 0;

    .content-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 30px 40px;
      width: 100%;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
    }

    .play-btn {
      font-size: 14px;
      cursor: pointer;

      &:hover {
        color: #3b86ff;
      }
    }

    .content-bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;

      .btn {
        height: 36px;
        width: 105px;
        line-height: 36px;
        background: #dcdcdc;
        border-radius: 50px;
        text-align: center;
        cursor: pointer;
        user-select: none;
      }

      .total {
        margin: 0 30px;
      }
    }

    .operation-content {
      margin-top: 10px;
      font-size: 14px;
      color: #bfbfbf;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .operation-btn {
        cursor: pointer;

        > i {
          font-size: 14px;
          margin-left: 6px;
        }
      }

      .tools {
        display: flex;
        align-items: center;

        .icon-tianjia {
          font-size: 14px;
          margin-right: 4px;
        }

        .word-checkbox {
          margin-left: 15px;
        }
      }
    }

    .recite-content {
      height: 437px;

      .content-header {
        display: flex;
        justify-content: space-between;
        font-size: 14px;

        .show-explain {
          margin-left: 30px;
          cursor: pointer;
        }
      }

      .content-center {
        font-size: 30px;
        text-align: center;
        font-weight: bold;
      }
    }

    .icon-eye {
      font-size: 14px;
      color: #1f1f40;
      margin-right: 20px;
      transform: translateY(3px);
      cursor: pointer;

      &:hover {
        color: #3b86ff;
      }
    }

    .parse-label {
      min-width: 50px;
    }

    .dictate-content {
      height: 315px;

      .content-header {
        position: relative;

        &.red {
          .el-input__inner,
          .error-text {
            color: #ff606d;
          }
        }

        &.green {
          .el-input__inner,
          .error-text {
            color: #36c626;
          }
        }

        .word-input {
          width: 100%;
          font-size: 30px;

          &.error {
            .el-input__inner {
              text-decoration: line-through;
            }
          }

          .el-input__inner {
            border-left: none;
            border-right: none;
            border-top: none;
            border-radius: 0;
            padding: 30px 100px 35px 35px;

            &:focus {
              border-color: #3b86ff;
            }
          }

          &.el-input.is-disabled .el-input__inner {
            background: none;
          }
        }

        .audio-btn {
          position: absolute;
          left: 0;
          top: 22px;
        }

        .error-text {
          position: absolute;
          font-size: 12px;
          left: 35px;
          bottom: 1px;
        }

        .right-btn {
          position: absolute;
          right: 0;
          top: 16px;

          .check-btn {
            width: 80px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            border-radius: 50px;
            cursor: pointer;
            font-size: 14px;
            color: #3b86ff;
            background: #c9e2ff;

            &.disabled {
              color: #bfbfbf;
              background: #dcdcdc;
              pointer-events: none;
            }
          }
        }
      }

      .content-center {
        display: flex;
        font-size: 14px;
        margin-top: 20px;

        .right-word {
          margin-left: 34px;
          font-size: 30px;
          color: #36c626;
        }
      }
    }
  }
}

.wordLearn-container {
  .el-main {
    background: rgba(244, 244, 244, 0.5);
  }
}
</style>
